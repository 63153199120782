import { WINDOW } from "@utils/constants";
import { isExists, isPC, isTabletAndMobile, isMobile } from "@utils/validate";

const Custom = function () {
  /**
   * handleSetPositionSliderArrow
   */
  this.handleSetPositionSliderArrow = () => {
    const EL_PRODUCT_SLIDER = ".product-slider";
    const EL_SLICK_ARROW = ".slick-arrow";
    const CLASS_NAME_RATIO = ".ratio";

    if (!isExists(EL_PRODUCT_SLIDER)) {
      return;
    }

    $(EL_PRODUCT_SLIDER).on("init", function (e, slick) {
      const productThumbHeight = slick.$slider.find(CLASS_NAME_RATIO).height();
      slick.$slider.find(EL_SLICK_ARROW).css("top", productThumbHeight / 2);
    });
  };

  /**
   * handleToggleSidebar
   */
  this.handleToggleSidebar = () => {
    const sidebar = document.getElementById("sidebar");
    if (!isExists(sidebar)) {
      return;
    }
    isPC() ? $(sidebar).addClass("show") : $(sidebar).removeClass("show");
  };

  /**
   * handleQuantityProduct
   */
  this.handleQuantityProduct = () => {
    let el = $(".product-quantity"),
      elAdd = $(".add"),
      elSub = $(".sub"),
      max = parseInt(el.find("input").attr("max"));
    if (!isExists(el)) {
      return;
    }
    elAdd.on("click", function () {
      if ($(this).prev().val() < max) {
        $(this)
          .prev()
          .val(+$(this).prev().val() + 1);
      }
    });
    elSub.on("click", function () {
      if ($(this).next().val() > 1) {
        if ($(this).next().val() > 1)
          $(this)
            .next()
            .val(+$(this).next().val() - 1);
      }
    });
  };

  this.handleCollapseProductDetail = () => {
    const btnClose1 = ".readmore .close";
    const btnClose2 = ".js-close";

    if (isExists(btnClose1)) {
      $(btnClose1).on("click", function (e) {
        e.preventDefault();
        window.scrollTo({
          top: 0
        });
        setTimeout(function () {
          $("#info").collapse("hide");
          $(btnClose2).trigger("click");
        }, 700);
      });
    }

    if (isExists(btnClose2)) {
      $(btnClose2).on("click", function (e) {
        e.preventDefault();
        const $this = $(this);
        checkCollapse($this);
      });
    }
    function checkCollapse(target) {
      return target.hasClass("collapsed")
        ? target.text("Xem chi tiết »")
        : target.text("Thu gọn »");
    }
  };

  this.handleEqualHeight = (container, vari) => {
    const target = container;
    const item = $(target).find(".js-height-item");
    // $(item).style.height = "auto";
    var heightBet = 0;
    $.each(item, function (i, val) {
      val.style.height = "auto";
      const height = val.clientHeight;
      if (height > heightBet) {
        heightBet = height;
      }
    });
    $.each(item, function (i, el) {
      if (isMobile()) {
        vari === false ? (el.style.height = "auto") : (el.style.height = heightBet + "px");
      } else {
        el.style.height = heightBet + "px";
      }
      if (i == item.length) {
        heightBet = 0;
      }
    });
  };

  this.init = function () {
    let self = this;
    self.handleSetPositionSliderArrow();
    self.handleToggleSidebar();
    self.handleQuantityProduct();
    self.handleCollapseProductDetail();

    $('[data-fancybox="images"]').fancybox({
      thumbs: {
        autoStart: true
      }
    });

    WINDOW.on("load", function () {
      self.handleEqualHeight("#product-slider", true);
      self.handleEqualHeight("#customer-slider", false);
    });
    WINDOW.on("resize", function () {
      setTimeout(function () {
        self.handleEqualHeight("#product-slider", true);
        self.handleEqualHeight("#customer-slider", false);
      });
    });

    WINDOW.resize(function () {
      self.handleSetPositionSliderArrow();
      !isTabletAndMobile && self.handleToggleSidebar();
    });
  };
  return this.init();
};
export default Custom;
