/**
 * Menu
 */

"use strict";
import { WINDOW, DOCUMENT, CLASS_BODY, CLASS_HIDDEN } from "@utils/constants";
import { isPC, isExists, isTabletAndMobile } from "@utils/validate";

const Menu = function () {
  const CLASS_NAV = ".nav-navbar";
  const CLASS_NAV_LINK = ".dropdown .nav-link";
  const CLASS_DROPDOWN = ".dropdown";
  const CLASS_DROPDOWN_CONTENT = ".dropdown-content";
  const IS_ACTIVE = "active";
  const STR_OPEN_MENU = "open-menu";
  const ID_TOGGLE_MENU = "#toggle-menu";
  const elNavbarCollapse = ".navbar-collapse";
  const elClickMore = ".nav-link-more";

  /**
   * openMenu function
   */
  this.openMenu = function () {
    this.removeActionWhenResize();
    $(CLASS_BODY).addClass(`${STR_OPEN_MENU} ${CLASS_HIDDEN}`);
  };
  /**
   * closeMenu function
   */
  this.closeMenu = function () {
    $(CLASS_BODY).removeClass(`${STR_OPEN_MENU} ${CLASS_HIDDEN}`);
  };
  /**
   * handleClickMenu function
   */
  this.handleClickMenu = function () {
    let self = this;
    $(ID_TOGGLE_MENU).on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(CLASS_BODY).hasClass(STR_OPEN_MENU) ? self.closeMenu() : self.openMenu();
    });

    DOCUMENT.on("click", ".overlay", function (e) {
      e.stopPropagation();
      self.closeMenu();
    });
  };
  /**
   * handleMenuDropdown
   * @return {void}
   */
  this.handleMenuDropdown = function () {
    const that = this;
    $(CLASS_NAV).each(function () {
      let self = $(this),
        elClick = self.find(CLASS_NAV_LINK);

      elClick.on("click", function (e) {
        if (isTabletAndMobile()) {
          e.stopPropagation();
          e.preventDefault();
          const parent = $(this).parent();
          parent.toggleClass(IS_ACTIVE).find(CLASS_DROPDOWN_CONTENT).slideToggle(250);
          $(CLASS_NAV_LINK)
            .not($(this))
            .parent()
            .removeClass(IS_ACTIVE)
            .find(CLASS_DROPDOWN_CONTENT)
            .stop()
            .slideUp(250);
          that.hanldeCollapseDropdownMenu(".only_sp");
        }
      });
    });
  };
  this.removeActionWhenResize = function () {
    $(CLASS_BODY).find(CLASS_DROPDOWN).removeClass(IS_ACTIVE);
    $(CLASS_BODY).find(CLASS_DROPDOWN_CONTENT).removeAttr("style");
    $(elNavbarCollapse).find(".navbar-nav").removeClass("is-collapse").removeAttr("style");
    $(elClickMore).unbind("click");
  };

  this.hanldeCollapseDropdownMenu = function (parent) {
    if (!isExists(elNavbarCollapse)) return;
    $(parent)
      .find(elNavbarCollapse)
      .each(function () {
        const elUl = $("ul", this);
        const elLi = $("ul li", this);
        const getCount = $(this).data("count");
        const textShow = $(this).attr("data-text-show");
        const textHide = $(this).attr("data-text-hide");
        const getListLi = elUl.children();
        const getHeightLi = elLi.height();
        const calcHeight = getHeightLi * getCount;
        const contentLink = `<a class="nav-link-more" href="#">${textShow}</a>`;
        const totalLi = getListLi.length;

        if (totalLi > getCount) {
          elUl.animate({ height: calcHeight }, 150);
          $(this).find(elClickMore).length !== 1 && $(this).append(contentLink);
          clickShow($(this), textShow, textHide, calcHeight, totalLi, getHeightLi);
        }
      });

    function clickShow(el, textShow, textHide, calcHeight, totalLi, heightLi) {
      let btn = el.find(elClickMore);
      btn.on("click", function (e) {
        e.preventDefault();
        let element = $(this).prev();
        element.toggleClass("is-collapse");

        if (element.hasClass("is-collapse")) {
          element.animate({ height: totalLi * heightLi }, 150);
          btn.text(textHide);
        } else {
          element.animate({ height: calcHeight }, 150);
          btn.text(textShow);
        }
      });
    }
  };

  this.init = function () {
    if (!isExists(CLASS_NAV)) {
      return;
    }
    let self = this;
    this.handleClickMenu();
    this.handleMenuDropdown();
    this.hanldeCollapseDropdownMenu(".only_pc");

    WINDOW.resize(function () {
      if (!isTabletAndMobile()) {
        self.removeActionWhenResize();
        self.closeMenu();
      }
      if (isPC()) {
        self.hanldeCollapseDropdownMenu(".only_pc");
      }
    });
  };

  return this.init();
};
export default Menu;
